import { useEffect, useRef, useState } from "react"
import { Spinner } from "react-activity"
import { Plus, X } from "react-feather"
import Image from "../../../components/Image";
import RadioCheck from "../../../assets/images/check_icon.svg";
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ActionButton } from "../../../components/ActionButton"
import { FlatButton, Paragraph, Quote, Text, TextButton, Title, View, Radio } from "../../../components/BaseComponents/styles"
import { Table, TBody, THead } from "../../../components/DataTable/styles"
import { Line } from "../../../components/Line/styles"
import { Modal } from "../../../components/Modal"
import TextBox from "../../../components/TextBox"
import Employees from "../../../services/Employees"
import { Colors } from "../../../values/colors"

const userModel = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  sector: '',
  subsector: '',
  business_unit: '',
  phone: '',
  parent_id: 0,
  dependency_type: ''
}

export const AddDependentModal = ({ open, onClose, onAddUser, data = {} }) => {

  const { t } = useTranslation('common');

  const [userList, setUserList] = useState([userModel]);
  const [importCsvModalVisible, setImportCsvModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [dependentLimit, setDependentLimit] = useState('');

  const fileUpload = useRef();

  useEffect(() => {
    setSendEmail(true);
    setUserList([userModel]);
    setDependentLimit(data?.dependent_limit ? data.dependent_limit : 0);
  }, [open, data?.dependent_limit]);

  const renderRows = () => {
    return (userList.map((item, index) =>
      <tr key={open && item.id}>
        <td>
          <TextBox containerStyle={{ padding: 0 }} required className="field-input" value={userList[index]?.name || ''} onValueChange={(e) => handleChange('name', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} type={'email'} className="field-input" value={userList[index]?.email || ''} onValueChange={(e) => handleChange('email', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.cpf || ''} onValueChange={(e) => handleChange('cpf', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.phone || ''} onValueChange={(e) => handleChange('phone', e, index)} />
        </td>
        <td>
          <select
            style={{
              padding: '0.7rem',
              boxShadow: '0 0 0 1px #6244EB',
              outlineColor: '#6244EB',
            }}
            value={userList[index]?.dependency_type || ''}
            onChange={(e) => handleChange('dependency_type', e.target.value, index)}
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value="">Selecione</option>
            <option value="Cônjuge">Cônjuge</option>
            <option value="Filho">Filho</option>
          </select>
        </td>
        <td>
          {(index > 0 || userList.length > 1) && <X onClick={() => removeRow(index)} />}
        </td>
      </tr>
    ));
  }
  const renderRowsHolder = () => {
    return (
      <tr key={open}>
        <td>
         <Text size={14} color={'#485465'} >{data?.name || ''}</Text>
        </td>
        <td>
          <Text size={14} color={'#485465'} >{data?.email || ''}</Text>
        </td>
        <td>
          <Text size={14} color={'#485465'} >{data?.sector || ''}</Text>
        </td>
        <td>
          <Text size={14} color={'#485465'} >{data?.business_unit || ''}</Text>
        </td>
        <td>
          <Text size={14} color={'#485465'} >{data?.cpf || ''}</Text>
        </td>
        <td>
          <Text size={14} color={'#485465'} >{data?.phone || ''}</Text>
        </td>
      </tr>
    );
  }

  const handleChange = (field, value, index) => {
    setUserList(prev => {
      let _list = [...prev];
      _list[index] = { ..._list[index], [field]: value };
      return _list;
    });
  }

  const addRow = () => {
    if (dependentLimit != null && (data.dependents.length + userList.length) < (dependentLimit)) {
      setUserList(prev => [...prev, { ...userModel, id: (prev[prev.length - 1] ? prev[prev.length - 1].id : 0) + 1 }]);
    } else {
      toast.error('Limite de dependentes já execedeu!')
    }
  }

  const removeRow = (index) => {
    setUserList(prev => {
      let _list = [...prev];
      _list.splice(index, 1);
      return _list;
    });
  }

  const optionsMap = {
    "Cônjuge": "spouse",
    "Filho": "son"
  };

  const saveData = async (e) => {
    e.preventDefault();
    setLoading(true);

    if ((data.dependents.length + userList.length) > dependentLimit) {
      toast.error('Limite de dependentes já execedeu!')
      return;
    }

    const updatedUserList = userList.map(item => ({
      ...item,
      parent_id: data?.id,
      dependency_type: optionsMap[item.dependency_type] || item.dependency_type,
      dependent_enabled: data?.dependent_enabled
    }));

    try {
      const response = await Employees.import({
        employees: updatedUserList,
        sendInvite: sendEmail
      });
      if (response.success) {
        toast.success('Dependentes adicionados com sucesso!');
        onClose();
        onAddUser && onAddUser();
        setLoading(false);
        setUserList([userModel]);
      } else {
        toast.error('Erro ao adicionar dependentes, verifique seus dados e tente novamente!');
      }
    } catch (e) {
      console.log(e);
      toast.error('Erro ao adicionar dependentes, verifique seus dados e tente novamente!');
      setLoading(false);
    }

  }

  const handleSelectFile = () => {
    fileUpload.current.click();
  }

  const handleUploadCsvFile = async (event) => {
    setLoadingUpload(true);
    const file = event.target.files[0];
    if (!file) {
      toast.error('Erro ao ler dados, verifique seu arquivo e tente novamente.');
    }

    let formData = new FormData();
    formData.append("csv", file);
    const response = await Employees.parseCsv(formData);
    if (response.success) {
      setUserList(prev => response.users.map((u, i) => ({ ...u, id: prev[prev.length - 1].id + i + 1 })));
      setImportCsvModalVisible(false);
      toast.success('Lista carregada com sucesso!');

    } else {
      toast.error('Erro ao ler dados, verifique seu arquivo e tente novamente.');
    }
    setLoadingUpload(false);
    fileUpload.current.value = null;
  }

  const handleImportCsvModal = () => {
    if ((data.dependents.length + userList.length) > dependentLimit) {
      toast.error('Limite de dependentes já execedeu!')
      return;
    }
    setImportCsvModalVisible(true)
  };

  return (
    <>
      <Modal containerStyle={{ width: 'unset', padding: '1rem' }} title={t('add-dependent')} open={open} onClose={onClose}>
        <form onSubmit={saveData}>
          <Text size={14} color={'#4A2CD3'} >{'Dados do titular'}</Text>
          <View style={{ overflow: 'auto', maxHeight: 400, backgroundColor: '#EDEAFB', borderRadius: 20, padding: 20, marginBottom: 10, marginTop: 10 }}>
            <Table className="no-border">
              <THead>
                <tr>
                  <th>
                    Nome
                  </th>
                  <th>
                    E-mail
                  </th>
                  <th>
                    Setor
                  </th>
                  <th>
                    Unidade de negócio
                  </th>
                  <th>
                    CPF
                  </th>
                  <th>
                    Celular
                  </th>
                  <th>
                  </th>
                </tr>
              </THead>
              <TBody>
                {renderRowsHolder()}
              </TBody>
            </Table>


          </View>
          <Text size={14} color={'#4A2CD3'} >{'Dados do dependente'}</Text>
          <View style={{ overflow: 'auto', maxHeight: 400 }}>
            <Table className="no-border">
              <THead>
                <tr>
                  <th>
                    Nome *
                  </th>
                  <th>
                    E-mail
                  </th>
                  <th>
                    CPF
                  </th>
                  <th>
                    Celular
                  </th>
                  <th>
                    Tipo de relacionamento
                  </th>
                  <th>
                  </th>
                </tr>
              </THead>
              <TBody>
                {renderRows()}
              </TBody>
            </Table>


          </View>

          <Line align={'space-between'} style={{ marginTop: '1rem' }}>
            <TextButton onClick={addRow} style={{ fontWeight: 800, fontSize: '18px', color: Colors.cadetBlue }}>+ {t('add_more_dependent')}</TextButton>
            <TextButton onClick={() => handleImportCsvModal()} style={{ fontWeight: 800, fontSize: '18px', color: Colors.cadetBlue }}>+ {t('import_via_csv')}</TextButton>
          </Line>

          <Line align={'start-end'} style={{ marginTop: '2rem', marginBottom: '1rem', flexFlow: 'row-reverse' }}>
            <ActionButton isLoading={loading}>{t('add')}</ActionButton>
          </Line>

        </form>


      </Modal>
      <Modal title={t('add_user_by_csv')} open={importCsvModalVisible} onClose={() => setImportCsvModalVisible(false)}>
        <Title style={{ fontSize: 16, color: '#485465', marginBottom: 15 }}>Usuário dependente da conta</Title>
        <Title style={{ fontSize: 16, color: '#8492A6', fontWeight: 400, marginBottom: 15, marginTop: 15 }}>Para enviar usuários dependentes, o arquivo deve estar de acordo com as regras abaixo:</Title>
        <Title style={{ fontSize: 16 }}>O arquivo deve estar de acordo com as regras abaixo:</Title>

        <View style={{ marginTop: '1rem' }}>
            <Paragraph style={{ color: '#485465', fontWeight: 700, display: "flex", alignItems: "flex-start" }}>
            <Image src={RadioCheck} width={40} height={20} />O cabecalho (primeira linha) deve incluir os nomes das colunas separadas por vírgula
            </Paragraph>
            <Quote size={14} weight={700}>
            Nome,Email,Cpf,Celular,Tipo de relacionamento
            </Quote>

            <Paragraph style={{ marginTop: '1rem', color: '#485465', fontWeight: 700, display: "flex", alignItems: "flex-start" }}>
            <Image src={RadioCheck} width={40} height={20} /> O cabecalho e as linhas seguintes devem ter a mesma quantidade de valores
            </Paragraph>
            <Quote size={14} weight={700}>
              Nome,Email,Cpf,Celular,Tipo de relacionamento<br />
              Dependente 1,dependente1@email.com,cpf,celular,filho<br />
              Dependente 2,dependente2@email.com,cpf,celular,cônjuge
            </Quote>

          <Line align={'center'} style={{ margin: '2rem 0 1rem 0', justifyContent: 'end' }}>
            {loadingUpload ?
              <Spinner color={Colors.primaryPurple} />
              :
              <FlatButton style={{ display: 'flex', borderRadius: 50 ,backgroundColor: Colors.primaryPurple, justifyContent: 'center', alignItems: 'center', fontSize: '1rem', height: 40, padding: '0 2rem' }} onClick={handleSelectFile}>
                <input type="file" ref={fileUpload} onChange={handleUploadCsvFile} accept=".csv" style={{ display: 'none', position: 'absolute' }} />
                <Plus style={{ padding: 4, borderRadius: 4 }} size={20} color={'white'} />
                <Text style={{ marginLeft: 10, fontSize: '1rem', color: 'white' }} weight={700} >{t('select_a_file')}</Text>
              </FlatButton>
            }
          </Line>
        </View>
      </Modal>
    </>
  );

}