import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/Modal";
import { Text, Title, View } from "../../../components/BaseComponents/styles";
import { Button } from "../../../components/Button";
import { Line } from "../../../components/Line/styles";
import { Colors } from "../../../values/colors";

export const ConfirmDisableCompaniesModal = ({
  open,
  handleConfirm,
  onClose,
  data,
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Modal title={t("disable_company")} open={open} onClose={onClose}>
        <>
          <View centered style={{ marginTop: "2rem" }}>
            <Title style={{ textAlign: "center", width: "60%" }}>
              {t("want_to_disable_company")}
            </Title>

            <Text
              style={{ textAlign: "center", width: "70%", marginTop: "1rem" }}
            >
              {t("message-disable-company-info-modal")}
            </Text>
          </View>
        </>
        <Line
          align={"center"}
          style={{ marginTop: "3rem", marginBottom: "2rem" }}
        >
          <Button onClick={() => handleConfirm(data)} color={Colors.defaultRed}>
            {t("confirm_deactivation")}
          </Button>
        </Line>
      </Modal>
    </>
  );
};
