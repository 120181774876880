/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from 'react';
import { Box } from "../../components/Box";
import DataTable from "../../components/DataTable";
import { UserContainer, UserPic, TextContainer, UserName, UserMail } from './styles';
import { HeadingsContainer, TocList, HeadingItem, HeadingLink } from './../../components/BaseComponents/styles'
import TextBox from '../../components/TextBox';
import { Mail, Plus } from 'react-feather';
import Image from "../../components/Image";
import ArrowUp from '../../assets/images/icon_arrow_up.svg';
import ArrowDown from '../../assets/images/icon_arrow_down.svg';
import { Colors } from '../../values/colors';
import { SearchBarCollapsible } from '../../components/SearchBarCollapsible';
import { DropdownMenu } from '../../components/DropdownMenu';
import { DropdownItem } from '../../components/DropdownMenu/styles';
import { useTranslation } from 'react-i18next';
import Employees from '../../services/Employees';
import Business from '../../services/Business';
import { FlatButton, Text, Title, View } from '../../components/BaseComponents/styles';
import { DropdownSelect } from '../../components/DropdownSelect/styles';
import { Button } from '../../components/Button';
import { useOutletContext } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { Line } from '../../components/Line/styles';
import { ErrorMessage, SuccessMessage } from '../../utils/alerts';
import { AddUserModal } from './modals/AddUsersModal';
import List from '../../services/List';
import { useCallback } from 'react';
import { phoneMask } from '../../utils/masks';
import { AddDependentModal } from './modals/AddDependentModal';

export default () => {

  const { t } = useTranslation('common');

  const [users, setUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);

  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [newUserModalVisible, setNewUserModalVisible] = useState(false);
  const [dependentModalVisible, setDependentModalVisible] = useState(false);
  const [owner, setOwner] = useState({});
  const [selectedUser, setSelectedUser] = useState();
  const [filters, setFilters] = useState({});
  const [sectors, setSectors] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [dependentMetas, setDependentMetas] = useState(null);

  const [setOptions] = useOutletContext();

  const loadFilters = async () => {
    const { business_units: _businessUnits } = await List.businessUnits();
    setBusinessUnits(_businessUnits.map(unit => unit.title).filter(unit => unit.length != 0));
    const { sectors: _sectors } = await List.sectors();
    setSectors(_sectors.map(sector => sector.title).filter(sector => sector.length != 0));
  }

  const fetchDependentMeta = async () => {
    try {
      const { values } = await Business.getPlanMetaByKey('dependent_enabled,dependent_limit');
      setDependentMetas(values);
    } catch (error) {
      console.log(error)
    }
  }
  
  const setFilter = (param, value) => {
    setFilters(prev => {
      let _newFilters = { ...prev };
      _newFilters[param] = value;
      return _newFilters;
    });
  }

  useEffect(() => {
    loadFilters();
  }, []);

  useEffect(() => {
    setOptions({
      start: <Text color={'white'} weight={700} style={{ textDecoration: 'underline' }}>{t('users_management')}</Text>,
      middle: <Text color={'white'} size={14}><Text color={'white'} size={30} weight={700}>{userCount}</Text> {t('registered_users')}</Text>,
      end: [
        <DropdownSelect onChange={(e) => setFilter('business_unit', e.currentTarget.value)} value={filters.business_unit}>
          <option value="">{t('all_business_units')}</option>
          {businessUnits.map(unit => { return <option key={unit} value={unit}>{unit}</option> })}
        </DropdownSelect>,
        <DropdownSelect onChange={(e) => setFilter('sector', e.currentTarget.value)} value={filters.sector}>
          <option value="">{t('all_sectors')}</option>
          {sectors.map(sector => { return <option key={sector} value={sector}>{sector}</option> })}
        </DropdownSelect>,
        <Button color={Colors.defaultBlue} onClick={() => setNewUserModalVisible(true)} icon={<Plus size={18} />}>{t('new_user')}</Button>
      ]
    });

    return () => setOptions(null);
  }, [sectors, businessUnits, userCount]);

  const getUsers = useCallback(async () => {
    const data = await Employees.get(filters.sector, filters.business_unit, searchText, page, perPage);
    setUsers(data.employees.map((employee, i) => ({ ...employee, key: i })));
    setUserCount(data.employee_count);
    fetchDependentMeta();
  }, [filters, page, perPage, searchText]);

  const refreshUsers = () => {
    setPage(prev => {
      if (prev === 1) {
        getUsers();
      }
      return 1;
    });
  }

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (searchText !== null) refreshUsers();
  }, [searchText]);

  
  let count = 0;
  const renderUserCol = (row) => {
    return (
      <UserContainer>
        <UserPic user={row} />
        <TextContainer>
          <UserName>{row?.name}</UserName>
          <UserMail>{row?.email}</UserMail>
          <UserMail>{phoneMask(row?.phone)}</UserMail>
        </TextContainer>
      </UserContainer>
    )
    
  };

  const downloadCSV = async () => {
    try {
      let business_unit = filters.business_unit?.length > 0 ? filters.business_unit : '';
      let sector = filters.sector?.length > 0 ? filters.sector : '';
      const { data } = await Employees.downloadCsv(business_unit, sector);
      let blob = new Blob([data], { type: 'application/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'usuarios_cadastrados.csv'
      link.click()
    } catch (e) {
      ErrorMessage({ message: 'Erro ao baixar documento.' });
    }
  }

  const downloadPDF = async () => {
    try {
      let business_unit = filters.business_unit?.length > 0 ? filters.business_unit : '';
      let sector = filters.sector?.length > 0 ? filters.sector : '';
      const { data } = await Employees.downloadPdf(business_unit, sector);
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      window.open(url, "_blank");
      SuccessMessage({ message: 'Dados exportados' });
    } catch (e) {
      ErrorMessage({ message: 'Erro ao baixar documento.' });
    }
  }

  const resendInvites = () => {
    try {
      const response = Employees.resendInvites();
      SuccessMessage({ message: 'Convites reenviados' });
    } catch (e) {
      ErrorMessage('Erro ao reenviar convites');
    }
  }

  const resendInvite = (id) => {
    try {
      const response = Employees.resendInvites({ id });
      SuccessMessage({ message: 'Convite reenviado' });
    } catch (e) {
      ErrorMessage({ message: 'Erro ao reenviar convites' });
    }
  }

  const openExportData = (data) => {
    setSelectedUser(data);
    setExportModalVisible(true);
  };

  const updateUser = async (data, index) => {
    await Employees.update(data);

    if (
      (data.sector || data.business_unit) &&
      !sectors.includes(data.sector) ||
      !businessUnits.includes(data.business_unit)) {
      loadFilters();
      setUsers((current) => {
        const _new = current;
        _new[index] = data;
        return _new;
      })
    }
  };

  const deleteUser = async () => {
    const toEdit = [...users];
    const indexToRemove = toEdit.findIndex(user => user.id === selectedUser.id);

    if (indexToRemove !== -1) {
      toEdit.splice(indexToRemove, 1);
    } else {
      toEdit.forEach(user => {
        if (user.dependents && user.dependents.length > 0) {
          const dependentIndex = user.dependents.findIndex(dep => dep.id === selectedUser.id);
          if (dependentIndex !== -1) {
            user.dependents.splice(dependentIndex, 1);
          }
        }
      });
    }
    setUsers(toEdit);
    await Employees.delete(selectedUser.id);
    setUserCount(prev => prev - 1);
    SuccessMessage({ message: selectedUser.parent_id ? 'Dependente excluído.' : 'Usuário excluído.' });
  };

  const handleDeleteUser = (index, entry) => {
    setSelectedUser({ ...entry, index: index });
    setDeleteModalVisible(true);
  }

  const handleAddDependent = (data) => {
    if (parseInt(data.dependent_limit) === data.dependents.length) {
      ErrorMessage({ message: 'Limite de dependentes já execedeu!' });
      return;
    }
    setOwner(data);
    setDependentModalVisible(true);
  }

  const [expandedRows, setExpandedRows] = useState(new Set());

  const handleExpandRow = (rowId) => {
    setExpandedRows(prev => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(rowId)) {
        newExpandedRows.delete(rowId);
      } else {
        newExpandedRows.add(rowId);
      }
      return newExpandedRows;
    });
  };

  return (
    <>
      <Box
        title={'Usuários Cadastrados'}
        noPadding={true}
        actions={
          <>
            <SearchBarCollapsible onSearch={(value) => setSearchText(value)} />
            <DropdownMenu style={{ marginLeft: '1rem' }}>
              <DropdownItem onClick={() => downloadCSV()}>{t('export_csv')}</DropdownItem>
              <DropdownItem onClick={() => downloadPDF()}>{t('export_pdf')}</DropdownItem>
              <DropdownItem onClick={() => resendInvites()}>{t('resend_invites')}</DropdownItem>
            </DropdownMenu>
          </>
        }
      >
        <DataTable
          collumns={[
            {
              title: '',
              key: 'expand',
              render: (row) => {
                if (row.dependents && row.dependents.length > 0) {
                  return (
                  <button style={{ width: '20px' }} onClick={() => handleExpandRow(row.id)}>
                    {expandedRows.has(row.id) ? <Image src={ArrowUp} height='20px' width='20px' /> : <Image src={ArrowDown} height='20px' width='20px' />}
                  </button>
                )} else if(row.parent_id) {
                  count = count +1;
                  return(
                    <HeadingsContainer>
                      <TocList>
                        <HeadingItem>
                          <HeadingLink className={count === 2 ? 'last-item' : ''}>
                              <UserName></UserName>
                          </HeadingLink>
                        </HeadingItem>
                      </TocList>
                    </HeadingsContainer>
                  )
                }
                }
            },
            { title: 'Nome', key: 'name', render: (row) => renderUserCol(row), editable: true, field: TextBox },
            { title: 'Setor', key: 'sector', editable: true, field: TextBox, fieldProps: { options: sectors } },
            { title: 'Unidade de Negócio', key: 'business_unit', editable: true, field: TextBox, fieldProps: { options: businessUnits } },
            { title: 'CPF', key: 'cpf' },
            {
              title: 'Status', key: 'invite', render: (row) => {
                const values = ['Convidado', 'Ativo', 'Recusado'];
                return row?.active ? values[row?.invite] : 'Removido';
              }
            },
            {
              title: '', key: 'dependent', render: (row) => {
                if(dependentMetas?.dependent_enabled) {
                  return(
                  <button
                    type="button"
                    style={{ color: dependentMetas.dependent_limit === row.dependents.length ? '#D3D3D3' : '#6240FF' , borderColor: dependentMetas.dependent_limit === row.dependents.length ? '#D3D3D3' : '#6240FF'}}
                    className=" text-blue-700 font-semibold shadow-sm text-sm hover:text-blue-500 py-3 px-2 border border-blue-500 hover:border-blue-400 rounded-3xl"
                    onClick={() =>  handleAddDependent(row)}
                  >
                      {t('add-dependent')}
                  </button>
                  );
                }
              }
            }
          ]}
          onUpdate={updateUser}
          onDelete={handleDeleteUser}
          data={users}
          totalEntries={userCount}
          actions={(row) => ([
            row?.invite === 0 && <FlatButton onClick={() => resendInvite(row.id)}>
              <Mail size={18} color={Colors.mediumGray} />
            </FlatButton>,
            // <FlatButton onClick={()=>openExportData(row)}>
            //   <ExternalLink size={18} color={Colors.mediumGray}/>
            // </FlatButton>
          ])}
          defaultPage={page}
          onPageChange={setPage}
          onPerPageChange={setPerPage}
          expandedRows={expandedRows}
        />
      </Box>

      <AddUserModal data={{
        dependent_enabled: dependentMetas?.dependent_enabled,
        sectors: sectors,
        businessUnits: businessUnits
      }} open={newUserModalVisible} onAddUser={() => refreshUsers()} onClose={() => setNewUserModalVisible(false)} />
      
      <AddDependentModal data={{...owner, dependent_limit: dependentMetas?.dependent_limit}} open={dependentModalVisible} onAddUser={() => refreshUsers()} onClose={() => setDependentModalVisible(false)} />

      <Modal title={t('export_data')} open={exportModalVisible} onClose={() => setExportModalVisible(false)}>
        {selectedUser &&
          <>
            <Line align={'center'} style={{ marginTop: '2rem' }}>
              <Title style={{ textAlign: 'center', width: '60%' }}>{t('want_to_export_employee_data')}</Title>
            </Line>

            <Line align={'center'} style={{ marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
              <UserPic style={{ marginRight: '2rem' }} size={100} user={selectedUser} />

              <View style={{ display: 'flex', flexDirection: 'column' }}>
                <Text size={16} weight={700}>{selectedUser.name}</Text>
                <Text size={14} style={{ textDecoration: 'underline' }}>{selectedUser.email}</Text>
                <View style={{ height: '1px', width: '100%', backgroundColor: Colors.ultraLightGray, marginTop: '1rem', marginBottom: '1rem' }}></View>
                <Line>
                  <Text style={{ marginRight: '1rem' }}>{selectedUser.sector}</Text>
                  <Text>{selectedUser.business_unit}</Text>
                </Line>
              </View>

            </Line>
          </>
        }
        <Line align={'center'} style={{ marginTop: '3rem', marginBottom: '2rem' }}>
          <Button color={Colors.cadetBlue}>{t('export_pdf')}</Button>
        </Line>
      </Modal>
      <Modal title={t('delete_user')} open={deleteModalVisible} onClose={() => setDeleteModalVisible(false)}>
        <>
          <Line align={'center'}>
            {selectedUser && <UserPic style={{ marginRight: '2rem' }} size={100} user={selectedUser} />}
          </Line>

          <View centered style={{ marginTop: '2rem' }}>
            <Title style={{ textAlign: 'center', width: '60%' }}>{t('want_to_delete_this_user')}</Title>

            <Text style={{ textAlign: 'center', width: '70%', marginTop: '1rem' }}>{'Esta ação tem efeito permanente.\nO usuário perderá o acesso e todo o progresso relacionado à empresa dentro do aplicativo.'}</Text>
          </View>
        </>
        <Line align={'center'} style={{ marginTop: '3rem', marginBottom: '2rem' }}>
          <Button onClick={() => (deleteUser(), setDeleteModalVisible(false))} color={Colors.defaultRed}>{t('confirm_delete')}</Button>
        </Line>
      </Modal>
    </>
  );
}